import React from 'react';
import HeaderStyles from '../styles/HeaderStyles';

const Header = () => {
  return (
    <HeaderStyles>
      Playlist Analyzer
    </HeaderStyles>
  )
}

export default Header;